.banner {
    width: 100%;
    height: 540px;
    background-image: url("../../../../assets/imgs/home_bg.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    .menu {
        margin-left: 200px;
        background-color: rgba(255, 95, 25, 0.65);
        padding: 15px 0;
        width: 296px;
        height: 100%;
        &-sub {
            width: 100%;
            height: 42px;
            background: #ffffff;
            display: flex;
            align-items: center;
            padding-left: 40px;
            overflow: hidden;
            // cursor: pointer;
            > .icon {
                width: 19px;
                height: 22px;
                margin-right: 10px;
            }

            > .text {
                font-size: 16px;
                font-weight: 400;
                color: #ff6116;
            }
        }

        &-item {
            width: 100%;
            padding: 23px 40px 8px 40px;
            display: flex;
            flex-wrap: wrap;

            > .line {
                display: flex;

                > a {
                    font-size: 16px;
                    padding-bottom: 15px;
                    cursor: pointer;
                    padding-right: 26px;
                    font-weight: 400;
                    color: #ffffff;
                    line-height: 22px;
                }
            }
        }
    }

    .content {
        flex: 1;
        padding: 90px 100px;
        display: flex;
        flex-direction: column;
        align-items: center;

        > .subtitle {
            font-size: 34px;
            font-weight: 400;
            color: #000000;
            line-height: 47px;
            letter-spacing: 1px;
        }
        > .title {
            font-size: 54px;
            font-weight: 400;
            color: #ff6116;
            line-height: 75px;
            letter-spacing: 1px;
        }
        > .bread {
            display: flex;
            align-items: center;
            justify-content: center;
            > span {
                font-size: 25px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #000000;
                line-height: 35px;
                letter-spacing: 1px;
            }

            span:nth-child(2) {
                color: rgba(255, 97, 22, 1);
            }
        }

        > .desc {
            font-size: 26px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #ff6116;
            line-height: 36px;
            letter-spacing: 1px;
            padding-top: 33px;
        }

        > .button {
            margin-top: 20px;
            width: 334px;
            height: 50px;
            background: #252524;
            border-radius: 8px;
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 19px;
            font-weight: 500;
            color: #ffffff;
            &:hover {
                opacity: 0.8;
                transition: opacity 0.2s;
            }
            &.red {
                margin-top: 13px;
                background: #f05506;
            }
        }
    }
}
