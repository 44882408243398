.content {
    padding: 74px 202px 90px 202px;
    width: 100%;
    background-image: url("../../../../assets/imgs/main4_bg.png");
    background-size: cover;
    background-repeat: no-repeat;

    > .title {
        display: flex;
        align-items: center;
        padding-bottom: 26px;
        justify-content: center;
        font-size: 36px;
        font-weight: 600;
        color: #fff;
        line-height: 51px;
        letter-spacing: 1px;
        font-family: PingFangSC-Semibold, PingFang SC;
    }

    > .main {
        width: 100%;
        display: flex;
        justify-content: space-between;

        > .img-block {
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            > .subtitle {
                font-size: 22px;
                text-align: left;
                width: 100%;
                font-family: PingFangSC-Semibold, PingFang SC;
                font-weight: 600;
                color: #ffffff;
                line-height: 31px;
                padding-bottom: 10px;
            }

            > .img-left {
                width: 436px;
                height: 244px;
            }

            > .img-right {
                width: 173px;
                height: 244px;
            }
        }

        > .text-block {
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            > .text {
                font-size: 19px;
                font-family: PingFangSC-Semibold, PingFang SC;
                font-weight: 600;
                color: #ffffff;
                line-height: 73px;
            }
        }
    }
}
