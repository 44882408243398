.mask {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 60;
    display: block;

    &-hidden {
        display: none;
    }

    &-enter {
        opacity: 0;
    }

    &-enter-active {
        opacity: 1;
        transition: opacity 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    }

    &-exit {
        opacity: 1;
    }

    &-exit-active {
        opacity: 0;
        transition: opacity 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    }
}