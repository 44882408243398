.container {
    background-color: rgb(255, 255, 255);
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    border-radius: 4px;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 11px 15px -7px, rgba(0, 0, 0, 0.14) 0px 24px 38px 3px,
        rgba(0, 0, 0, 0.12) 0px 9px 46px 8px;
    position: relative;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    height: 581px;
    max-height: 700px;
    width: 700px;

    > .header {
        width: 100%;
        height: 44px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 16px 0 24px;
        flex-shrink: 0;
        background-color: rgba(45, 142, 255, 1);
        > .subtitle {
            font-size: 13px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #ffffff;
        }

        > .close {
            cursor: pointer;
            width: 36px;
            height: 36px;
            display: flex;
            align-items: center;
            justify-content: center;
            > .icon {
                width: 14px;
                height: 14px;
            }
        }
    }

    > .content {
        width: 100%;
        // padding: 16px;
        flex: 1;
        display: flex;
        overflow: hidden;

        .list {
            overflow: scroll;
            flex: 1;
            padding: 16px;
            // width: 100%;
            &-item {
                width: 100%;
                display: flex;
                margin-bottom: 16px;

                &.flexRight {
                    justify-content: flex-end;

                    .box .message-box {
                        background-color: #46db7a;
                        color: #fff;
                    }
                }

                > .img {
                    width: 40px;
                    height: 40px;
                    flex-shrink: 0;
                    background-color: #f5f6fa;
                    border-radius: 50%;
                    overflow: hidden;
                    margin-right: 12px;
                    &.img-right {
                        margin-right: 0;
                        margin-left: 12px;
                    }
                }

                > .box {
                    display: flex;
                    flex-direction: column;
                    > .time {
                        font-size: 12px;
                        color: rgba(0, 0, 0, 0.25);
                    }
                    .message-box {
                        padding: 12px 12px;
                        background-color: #f5f6fa;
                        border-radius: 8px;
                        margin-bottom: 6px;
                        display: flex;
                        flex-direction: column;
                        &:last-child {
                            margin-bottom: 0;
                        }

                        &-text {
                            // padding-bottom: 12px;
                        }

                        &-options {
                            display: flex;
                            align-items: center;
                            flex-wrap: wrap;

                            > .option {
                                margin-top: 12px;
                                padding: 0 16px;
                                font-size: 14px;
                                min-width: none;
                                border-radius: 30px;
                                min-height: 0;
                                height: 30px;
                                margin-right: 12px;
                            }
                        }

                        &-form {
                            background-color: rgb(255, 255, 255);
                            color: rgba(0, 0, 0, 0.87);
                            transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
                            border-radius: 4px;
                            box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 1px -1px, rgba(0, 0, 0, 0.14) 0px 1px 1px 0px,
                                rgba(0, 0, 0, 0.12) 0px 1px 3px 0px;
                            overflow: hidden;
                            min-width: 275px;
                            padding: 24px;

                            &-title {
                                font-weight: 400;
                                font-size: 0.875rem;
                                line-height: 1.43;
                                letter-spacing: 0.01071em;
                                display: flex;
                                align-items: center;

                                > .icon {
                                    width: 24px;
                                    margin-right: 8px;
                                }
                            }

                            > input {
                                height: 40px;
                                border: 1px solid #efefef;
                                border-radius: 5px;
                                width: 250px;
                                padding: 0 12px;
                                margin-top: 12px;
                                &:focus {
                                    border-color: #0092ff;
                                    transition: all 0.3s;
                                }
                            }

                            > .error-message {
                                width: 100%;
                                font-size: 12px;
                                padding-top: 8px;
                                color: #ff6116;
                            }

                            > .submit {
                                margin-top: 12px;
                                padding: 0 16px;
                                font-size: 14px;
                                min-width: none;
                                border-radius: 24px;
                                min-height: 0;
                                height: 28px;
                            }
                        }
                    }
                }
            }
        }

        .right-panel {
            width: 198px;
            display: flex;
            flex-direction: column;
            border-left: 1px solid #efefef;

            &-nav {
                width: 100%;
                height: 39px;
                border-bottom: 1px solid #efefef;
                display: flex;
                align-items: center;
                font-size: 12px;
                text-align: center;
                position: relative;
                cursor: pointer;
                color: rgba(0, 0, 0, 0.45);
                justify-content: center;
                flex-shrink: 0;

                &::after {
                    content: "";
                    position: absolute;
                    bottom: -1px;
                    left: 50%;
                    transform: translateX(-50%);
                    height: 2px;
                    width: 20px;
                    background-color: #06cb94;
                }
            }
            &-body {
                overflow: auto;
            }
            &-info {
                width: 100%;
                display: flex;
                flex-direction: column;
                align-items: center;
                border-bottom: 1px solid #efefef;
                > .head-img {
                    padding: 20px 0 10px 0;
                    > img {
                        width: 60px;
                        height: 60px;
                        border-radius: 50%;
                    }
                }

                > .title {
                    font-size: 14px;
                    color: rgba(0, 0, 0, 0.85);
                }
                > .tag {
                    font-size: 12px;
                    color: rgba(0, 0, 0, 0.85);
                    background: #f5f6fa;
                    padding: 2px 10px;
                    margin-top: 6px;
                    border-radius: 10px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }

                > .stars {
                    display: flex;
                    align-items: center;
                    margin: 12px 0;
                    .star-item {
                        margin-right: 10px;
                        height: 12px;
                        width: 12px;
                        &:last-child {
                            margin-right: 0;
                        }
                    }
                }

                .statistic {
                    width: 100%;
                    display: flex;
                    padding-bottom: 12px;
                    &-item {
                        flex: 1;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        justify-content: center;
                        > .number {
                            color: #475669;
                            font-size: 22px;
                            height: 30px;
                            line-height: 30px;
                        }

                        > .text {
                            color: #8392a6;
                            font-size: 12px;
                        }
                    }
                }
            }

            &-evaluate {
                width: 100%;
                padding: 16px;

                > .subtitle {
                    font-size: 14px;
                    font-weight: 600;
                    line-height: 40px;
                    color: rgba(0, 0, 0, 0.85);
                }

                > .tags {
                    width: 100%;

                    > .tag-item {
                        border: 1px solid #d3dce6;
                        border-radius: 2px;
                        height: 26px;
                        color: #475669;
                        font-size: 12px;
                        padding: 0 2px;
                        margin-bottom: 8px;
                        line-height: 26px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        text-align: center;
                    }
                }

                > .comments {
                    width: 100%;

                    > .comment-item {
                        width: 100%;
                        font-size: 12px;
                        background: #f5f8fa;
                        border-radius: 3px;
                        padding: 5px;
                        margin-bottom: 5px;
                        word-break: break-word;

                        > .title {
                            color: #2dca93;
                        }
                        > .desc {
                            color: #475669;
                            line-height: 20px;
                        }
                    }
                }
            }
        }
    }
}

.text-right {
    text-align: right;
}
