.content {
    width: 100%;
    width: 100%;
    padding: 79px 202px 55px 202px;
    background-image: url("../../assets/imgs/main10_bg.png");
    background-size: cover;
    background-repeat: no-repeat;
    > .title {
        display: flex;
        align-items: center;
        padding-bottom: 19px;
        font-size: 29px;
        font-weight: 600;
        color: #fff;
        line-height: 41px;
        letter-spacing: 1px;
        font-family: PingFangSC-Semibold, PingFang SC;
    }

    > .text {
        width: 700px;
        height: 54px;
        font-size: 19px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #ffffff;
        margin-bottom: 19px;
        flex-shrink: 0;
        line-height: 27px;
    }

    > .button {
        width: 187px;
        height: 53px;
        border: 1px solid #979797;
        font-size: 19px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #ffffff;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        &:hover {
            opacity: 0.8;
            transition: opacity 0.2s;
        }
    }
}

.float-button {
    position: fixed;
    z-index: 3;
    width: 160px;
    height: auto;
    top: 80px;
    right: 0;
    cursor: pointer;
    bottom: auto;

    > img {
        width: 100%;
    }

    > .shake {
        position: absolute;
        top: 0;
        left: 0;
        animation: shakeTopx 1s linear infinite;
    }

    > .shake-left {
        position: absolute;
        bottom: 4px;
        left: 0;
        animation: shakeleft 1s linear infinite;
    }
}

@keyframes shakeleft {
    0% {
        margin-left: 0;
    }
    50% {
        margin-left: 3px;
    }
    100% {
        margin-left: 0;
    }
}

@keyframes shakeTopx {
    0%,
    100% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
        -webkit-transform-origin: 50% 0;
        transform-origin: 50% 0;
    }
    5% {
        -webkit-transform: rotate(2deg);
        transform: rotate(2deg);
    }
    10%,
    20%,
    30% {
        -webkit-transform: rotate(-4deg);
        transform: rotate(-4deg);
    }
    15%,
    25%,
    35% {
        -webkit-transform: rotate(4deg);
        transform: rotate(4deg);
    }
    40% {
        -webkit-transform: rotate(-2deg);
        transform: rotate(-2deg);
    }
    45% {
        -webkit-transform: rotate(2deg);
        transform: rotate(2deg);
    }
    50% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
}
