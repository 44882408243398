.ypm-toast-mask {
  z-index: 70;
}

.ypm-toast-wrap {
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 71;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  transform: translate(-50%, -50%);
  &-content {
    width: fit-content;
    min-width: 192px;
    max-width: 83vw;
    max-height: 70vh;
    overflow: auto;
    color: rgb(255 255 255 / 95%);
    font-weight: 400;
    font-size: 32px;
    line-height: 48px;
    text-align: center;
    word-break: break-all;
    background: rgb(0 0 0 / 65%);
    border-radius: 8px;

    // box-shadow: 0px 3px 5px -1px rgb(0 0 0 / 20%), 0px 6px 10px 0px rgb(0 0 0 / 14%), 0px 1px 18px 0px rgb(0 0 0 / 12%);
    pointer-events: all;
  }
}

.ypm-toast-wrap-text {
  padding: 24px 40px;
}

@media screen and (min-width: 600px) {
  .ypm-toast-mask {
    z-index: 70;
  }
  .ypm-toast-wrap {
    &-content {
      width: fit-content;
      min-width: 210px;
      max-width: 220px;
      max-height: 50vh;
      overflow: auto;
      color: rgb(255 255 255 / 95%);
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
      text-align: center;
      word-break: break-all;
      background: rgb(50 50 50);
      border-radius: 4px;
      box-shadow: 0px 3px 5px -1px rgb(0 0 0 / 20%), 0px 6px 10px 0px rgb(0 0 0 / 14%),
        0px 1px 18px 0px rgb(0 0 0 / 12%);
      pointer-events: all;
    }
  }

  .ypm-toast-wrap-text {
    padding: 12px 20px;
  }
}

.ypm-toast {
  &-enter {
    // transform: scale(0.75, 0.625);
    opacity: 0;
  }

  &-enter-active {
    transform: none;
    opacity: 1;
    transition: opacity 225ms;
  }

  &-exit {
    display: block;
    opacity: 1;
  }

  &-exit-active {
    // transform: scale(0.75, 0.625);
    opacity: 0;
    transition: opacity 225ms;
  }
}
