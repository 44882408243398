.content {
    padding: 77px 202px;
    background-color: #fff;
    > .title {
        display: flex;
        align-items: center;
        padding-bottom: 46px;
        justify-content: center;
        font-size: 36px;
        font-weight: 600;
        color: #262626;
        line-height: 51px;
        letter-spacing: 1px;
        font-family: PingFangSC-Semibold, PingFang SC;
    }

    .list {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 114px;
        &-item {
            background: #edf2f8;
            border-radius: 10px;
            width: 175px;
            height: 379px;
            background: #edf2f8;
            border-radius: 10px;
            position: relative;
            z-index: 1;

            > .img {
                position: absolute;
                z-index: 2;
                width: 100px;
                height: 100px;
                border-radius: 50%;
                object-fit: cover;
                top: 0;
                left: 50%;
                transform: translate(-50%, -50%);
                overflow: hidden;
            }

            > .title {
                width: 100%;
                padding-top: 61px;
                display: flex;
                align-items: flex-end;
                justify-content: center;

                > .name {
                    font-size: 19px;
                    font-family: PingFangSC-Medium, PingFang SC;
                    font-weight: 500;
                    color: #2a2a2a;
                    line-height: 27px;
                }

                > .desc {
                    font-size: 13px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #2a2a2a;
                    line-height: 27px;
                    display: flex;
                    padding-left: 14px;
                    &::before {
                        content: "|";
                        padding-right: 8px;
                    }
                }
            }

            > .main {
                padding: 18px 20px 20px 20px;

                > .text {
                    width: 100%;
                    font-size: 11px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #2a2a2a;
                    line-height: 29px;
                }
            }
        }
    }
}
