.content {
    width: 100%;
    width: 100%;
    padding: 83px 202px 83px 202px;
    background-image: url("../../../../assets/imgs/main6_bg.png");
    background-size: cover;
    background-repeat: no-repeat;
    > .title {
        display: flex;
        align-items: center;
        padding-bottom: 53px;
        justify-content: center;
        font-size: 36px;
        font-weight: 600;
        color: #fff;
        line-height: 51px;
        letter-spacing: 1px;
        font-family: PingFangSC-Semibold, PingFang SC;
    }

    .list {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        &-item {
            width: 174px;
            height: 454px;
            background: #ffffff;
            border-radius: 10px;
            border: 1px solid #c1c1c1;
            margin-right: 31px;
            padding: 16px;
            &:last-child {
                margin-right: 0;
            }

            &-header {
                display: flex;
                align-items: center;
                justify-content: space-between;
                position: relative;
                > .number {
                    width: 38px;
                    height: 38px;
                }

                > .arrow {
                    width: 69px;
                    position: relative;
                    height: 69px;
                    right: -36px;
                }
            }

            &-subtitle {
                font-size: 22px;
                font-family: PingFangSC-Semibold, PingFang SC;
                font-weight: 600;
                color: #2a2a2a;
                line-height: 31px;
            }

            &-text {
                padding-top: 9px;
                font-size: 16px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #2a2a2a;
                line-height: 29px;
            }
        }
    }
}
