.content {
    width: 100%;
    background-color: #fff;
    padding: 85px 202px 77px 202px;
    > .title {
        display: flex;
        align-items: center;
        padding-bottom: 64px;
        justify-content: center;
        font-size: 36px;
        font-weight: 600;
        color: #262626;
        line-height: 51px;
        letter-spacing: 1px;
        font-family: PingFangSC-Semibold, PingFang SC;
    }

    .list {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        &-item {
            width: 175px;
            height: 313px;
            background: #ffffff;
            border-radius: 10px;
            border: 1px solid #c1c1c1;
            overflow: hidden;

            > .img {
                width: 174px;
                height: 133px;
                object-fit: cover;
            }

            > .text {
                padding: 18px 17px 0px 17px;
                font-size: 16px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #2a2a2a;
                line-height: 29px;
            }
        }
    }
}
