$btn-transition-duration: 0.28s !default;

.btn {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 40px;
    padding: 8px 12px;
    color: #fff;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    text-decoration: none;
    background-color: #0092ff;
    border: none;
    border-radius: 4px;
    outline: 0;
    cursor: pointer;
    transition-duration: $btn-transition-duration;
    transition-property: box-shadow, transform, opacity;
    user-select: none;
    &.loading {
        opacity: 0.8;
    }
    &:hover:not(.outlined, .text, .loading) {
        text-decoration: none;
        box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%),
            0px 1px 10px 0px rgb(0 0 0 / 12%);
    }

    &:active:not(.outlined, .text, .loading) {
        text-decoration: none;
        box-shadow: 0px 5px 5px -3px rgb(0 0 0 / 20%), 0px 8px 10px 2px rgb(0 0 0 / 14%),
            0px 3px 14px 2px rgb(0 0 0 / 12%);
    }

    .loading-block {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
    }
}
