.dialog {
  z-index: 99 !important;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 60;
  display: block;

  &-enter {
    opacity: 0;
    transform: scale(0.1);
  }

  &-enter-active {
    opacity: 1;
    transform: scale(1);
    transition: opacity 200ms, transform 250ms;
  }

  &-exit {
    opacity: 1;
    transform: scale(1);
  }

  &-exit-active {
    opacity: 0;
    transform: scale(0.1);

    transition: opacity 200ms, transform 250ms;
  }

  &-wrap {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 600px;
    max-width: 600px;
    transform: translate(-50%, -50%);
  }

  &-content {
    width: 100%;
    overflow: hidden;
    font-size: 14px;
    background-color: #fff;
    border-radius: 2px;
  }

  &-header {
    height: 44px;
    background: #0092ff;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 16px;
    border-radius: 2px 2px 0px 0px;

    &-title {
      font-size: 16px;
      font-weight: 600;
      color: #ffffff;
      line-height: 22px;
    }
  }

  &-body {
    position: relative;

    &-title {
      justify-content: flex-start;
      margin-bottom: 0;
      padding: 16px 0;
      color: rgb(0 0 0 / 85%);
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      text-align: left;
    }
  }

  &-footer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    padding: 16px;

    &-button {
      flex: none;
      min-width: 84px;
      height: 34px;
      min-height: 0;
      margin-right: 11px;
      padding: 0 24px;
      padding: 0;
      font-weight: 400;
      font-size: 14px;
      border-radius: 2px;

      &:last-child {
        margin-right: 0;
      }
    }
  }
}
