.content {
    padding: 80px 208px 27px 208px;
    width: 100%;
    background-image: url("../../../../assets/imgs/main4_bg.png");
    background-size: cover;
    background-repeat: no-repeat;

    &-title {
        display: flex;
        justify-content: center;
        width: 100%;
        align-items: center;
        font-size: 38px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #ffffff;
        line-height: 53px;
        letter-spacing: 1px;
        padding-bottom: 41px;
    }

    > .main {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        .list {
            width: 100%;
            display: flex;
            justify-content: space-between;
            padding-bottom: 31px;
            &-item {
                width: 233px;
                height: 167px;
                background: #ffffff;
                padding: 18px 23px 14px 23px;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                > .title {
                    width: 100%;
                    text-align: center;
                    font-size: 18px;
                    font-family: PingFangSC-Semibold, PingFang SC;
                    font-weight: 600;
                    color: #2a2a2a;
                    line-height: 25px;
                }

                > .info {
                    width: 100%;
                    display: flex;
                    justify-content: space-between;
                    > .img {
                        width: 57px;
                        height: 71px;
                    }

                    > .right {
                        display: flex;
                        flex-direction: column;
                        justify-content: space-between;
                        align-items: center;
                        > .text {
                            font-size: 15px;
                            font-family: PingFangSC-Semibold, PingFang SC;
                            font-weight: 600;
                            color: #2a2a2a;
                            line-height: 21px;
                        }

                        > .btn {
                            width: 122px;
                            height: 37px;
                            min-height: 0;
                            background: #ffffff;
                            border-radius: 18px;
                            border: 1px solid #ff7634;
                            font-size: 14px;
                            font-family: PingFangSC-Regular, PingFang SC;
                            font-weight: 400;
                            color: #ff6116;
                        }
                    }
                }
            }
        }

        .button {
            width: 249px;
            height: 72px;
            background: linear-gradient(270deg, #e44d26 0%, #f16529 100%);
            border-radius: 54px;
            font-size: 19px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #ffffff;
        }
    }
}
