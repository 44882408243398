.content {
    width: 100%;
    width: 100%;
    padding: 90px 202px 98px 202px;
    background-image: url("../../../../assets/imgs/main8_bg.png");
    background-size: cover;
    background-repeat: no-repeat;
    > .title {
        display: flex;
        align-items: center;
        padding-bottom: 53px;
        justify-content: center;
        font-size: 36px;
        font-weight: 600;
        color: #fff;
        line-height: 51px;
        letter-spacing: 1px;
        font-family: PingFangSC-Semibold, PingFang SC;
    }

    .list {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;

        &-item {
            width: 225px;
            height: 120px;
            background: #ffffff;
            border-radius: 10px;
            border: 1px solid #c1c1c1;
            padding: 0 26px 0 42px;
            display: flex;
            align-items: center;
            justify-content: center;
            .text {
                font-size: 22px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #2a2a2a;
                line-height: 31px;
                position: relative;

                &::before {
                    content: "";
                    position: absolute;
                    left: -20px;
                    top: 0;
                    transform: translateY(100%);
                    width: 10px;
                    height: 10px;
                    background: #ff5b19;
                    border-radius: 50%;
                }
            }
        }
    }
}
