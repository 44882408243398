.content {
    width: 100%;
    padding: 64px 0;
    background-color: #fff;
    > .title {
        display: flex;
        align-items: center;
        padding-bottom: 46px;
        justify-content: center;
        font-size: 36px;
        font-weight: 600;
        color: #262626;
        line-height: 51px;
        letter-spacing: 2px;
        font-family: PingFangSC-Semibold, PingFang SC;
        > span {
            color: rgba(240, 85, 6, 1);
        }
    }

    > .body {
        width: 996px;
        margin: 0 auto;

        > .top {
            width: 100%;
            display: flex;
            justify-content: space-between;

            > .block {
                display: flex;
                flex-direction: column;

                > .title {
                    font-size: 19px;
                    font-family: PingFangSC-Semibold, PingFang SC;
                    font-weight: 600;
                    color: #f05506;
                    line-height: 27px;
                    padding-bottom: 8px;
                }

                .list {
                    height: 208px;
                    border: 1px solid #c5c5c5;
                    border-top: 3px solid rgba(240, 85, 6, 1);
                    padding: 7px;
                    display: flex;
                    flex-wrap: wrap;
                    width: 310px;

                    &-item {
                        width: 83px;
                        height: 34px;
                        background: #6d778b;
                        border-radius: 4px;
                        user-select: none;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        font-size: 15px;
                        font-weight: 500;
                        margin: 7px;
                        color: #ffffff;
                        cursor: pointer;
                        &:hover {
                            opacity: 0.8;
                            transition: opacity 0.2s;
                        }
                    }
                }

                > li {
                    width: 413px;
                    height: 208px;
                    border: 1px solid #c5c5c5;
                    border-top: 3px solid rgba(240, 85, 6, 1);
                    padding: 0 15px;
                    display: flex;
                    flex-wrap: wrap;
                    list-style: none;
                    justify-content: space-around;
                    flex-direction: column;

                    > ul {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        overflow: hidden;
                        // margin-bottom: 12px;
                        &:last-child {
                            margin-bottom: 0;
                        }
                        &::before {
                            content: "";
                            width: 4px;
                            height: 4px;
                            background: #ff0100;
                            border-radius: 50%;
                            margin-right: 7px;
                        }

                        > .text {
                            font-size: 13px;
                            font-family: PingFangSC-Medium, PingFang SC;
                            font-weight: 500;
                            color: #262626;
                            line-height: 18px;
                            flex: 1;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                            cursor: pointer;
                        }

                        &:last-child {
                            > .text {
                                color: #ff6116;
                            }

                            > .button {
                                background-color: #fff;
                                border: 1px solid #ff6116;
                                color: #ff6116;
                            }
                        }

                        > .button {
                            width: 75px;
                            height: 25px;
                            background: #ff6116;
                            border-radius: 2px;
                            cursor: pointer;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            font-size: 10px;
                            font-family: PingFangSC-Medium, PingFang SC;
                            font-weight: 500;
                            color: #ffffff;
                            &:hover {
                                opacity: 0.8;
                                transition: opacity 0.2s;
                            }
                        }
                    }
                }

                > .block-right {
                    width: 211px;
                    height: 208px;
                    background-image: url("../../../../assets/imgs/main1_right_bg.png");
                    background-size: 100% 100%;
                    background-repeat: no-repeat;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    > .tip {
                        height: 27px;
                        background: #ff5e00;
                        width: 100%;
                        display: flex;
                        margin-top: 18px;
                        align-items: center;
                        justify-content: center;
                        font-size: 14px;
                        font-family: PingFangSC-Semibold, PingFang SC;
                        font-weight: 600;
                        color: #ffffff;
                    }

                    > .title {
                        font-size: 26px;
                        font-family: PingFangSC-Medium, PingFang SC;
                        font-weight: 500;
                        color: #ffffff;
                        line-height: 36px;
                        padding-top: 8px;
                    }

                    > .subtitle {
                        font-size: 20px;
                        font-family: PingFangSC-Medium, PingFang SC;
                        font-weight: 500;
                        color: #ffffff;
                        line-height: 29px;
                        padding-top: 12px;
                    }

                    > .button {
                        margin-top: 21px;
                        width: 126px;
                        height: 34px;
                        background: #9013fe;
                        border-radius: 17px;
                        display: flex;
                        align-items: center;
                        user-select: none;
                        justify-content: center;
                        font-size: 13px;
                        font-family: PingFangSC-Medium, PingFang SC;
                        font-weight: 500;
                        color: #ffffff;
                        cursor: pointer;
                        &:hover {
                            opacity: 0.8;
                            transition: opacity 0.2s;
                        }
                    }
                }
            }
        }

        > .center {
            width: 100%;
            display: flex;
            justify-content: space-between;
            margin: 30px 0;

            > .card {
                width: 226px;
                height: 127px;
                background-image: url("../../../../assets/imgs/main1_center_bg.png");
                background-size: 100% 100%;
                background-repeat: no-repeat;
                display: flex;
                flex-direction: column;
                align-items: center;
                > .title {
                    padding-top: 23px;
                    font-size: 26px;
                    font-family: PingFangSC-Semibold, PingFang SC;
                    font-weight: 600;
                    color: #ffffff;
                    line-height: 36px;
                }

                > .desc {
                    font-size: 13px;
                    font-family: PingFangSC-Semibold, PingFang SC;
                    font-weight: 600;
                    color: #ffffff;
                    line-height: 18px;
                    padding-top: 7px;
                    text-decoration: underline;
                    cursor: pointer;
                }

                > .labels {
                    display: flex;
                    padding: 7px 44px 0 44px;
                    flex-wrap: wrap;
                    > .label {
                        font-size: 13px;
                        font-family: PingFangSC-Semibold, PingFang SC;
                        font-weight: 600;
                        color: #ffffff;
                        cursor: pointer;
                        text-decoration: underline;
                        line-height: 18px;
                        &:nth-child(odd) {
                            margin-right: 30px;
                        }
                    }
                }
            }
        }

        > .bottom {
            width: 100%;
            display: flex;
            justify-content: space-between;
            > .block {
                display: flex;
                flex-direction: column;

                > .title {
                    font-size: 19px;
                    font-family: PingFangSC-Semibold, PingFang SC;
                    font-weight: 600;
                    color: #f05506;
                    line-height: 27px;
                    padding-bottom: 8px;
                }

                .box1 {
                    width: 310px;
                    height: 208px;
                    border-radius: 12px;
                    border: 1px solid #c5c5c5;
                    > .tip {
                        width: 100%;
                        height: 27px;
                        background: #ff6116;
                        display: flex;
                        align-items: center;
                        padding-left: 26px;
                        font-size: 14px;
                        font-family: PingFangSC-Semibold, PingFang SC;
                        font-weight: 600;
                        color: #ffffff;
                        margin-top: 26px;
                    }

                    &-content {
                        width: 100%;
                        padding-left: 26px;

                        > .subtitle {
                            width: 100%;
                            padding: 15px 0 8px 0;
                            font-size: 19px;
                            font-family: PingFangSC-Semibold, PingFang SC;
                            font-weight: 600;
                            color: #262626;
                            line-height: 27px;
                        }

                        > .desc {
                            font-size: 15px;
                            font-family: PingFangSC-Regular, PingFang SC;
                            font-weight: 400;
                            color: #262626;
                            line-height: 20px;
                        }
                    }
                }

                > li {
                    width: 413px;
                    height: 208px;
                    border: 1px solid #c5c5c5;
                    border-top: 3px solid rgba(240, 85, 6, 1);
                    padding: 0 15px;
                    display: flex;
                    flex-wrap: wrap;
                    list-style: none;
                    justify-content: space-around;
                    flex-direction: column;

                    > ul {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        overflow: hidden;
                        // margin-bottom: 12px;
                        &:last-child {
                            margin-bottom: 0;
                        }
                        &::before {
                            content: "";
                            width: 4px;
                            height: 4px;
                            background: #ff0100;
                            border-radius: 50%;
                            margin-right: 7px;
                        }

                        > .text {
                            font-size: 13px;
                            font-family: PingFangSC-Medium, PingFang SC;
                            font-weight: 500;
                            color: #262626;
                            line-height: 18px;
                            flex: 1;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                            cursor: pointer;
                        }

                        &:last-child {
                            > .text {
                                color: #ff6116;
                            }

                            > .button {
                                background-color: #fff;
                                border: 1px solid #ff6116;
                                color: #ff6116;
                            }
                        }

                        > .button {
                            width: 75px;
                            height: 25px;
                            background: #ff6116;
                            border-radius: 2px;
                            user-select: none;
                            cursor: pointer;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            font-size: 10px;
                            font-family: PingFangSC-Medium, PingFang SC;
                            font-weight: 500;
                            color: #ffffff;
                            &:hover {
                                opacity: 0.8;
                                transition: opacity 0.2s;
                            }
                        }
                    }
                }
                > .block-right {
                    width: 211px;
                    height: 208px;
                    background-image: url("../../../../assets/imgs/main1_bottom_bg.png");
                    background-size: 100% 100%;
                    background-repeat: no-repeat;
                    display: flex;
                    flex-direction: column;

                    > .tip {
                        width: 101px;
                        height: 23px;
                        background: #ff5e00;
                        margin: 22px 0 0 18px;
                        font-size: 10px;
                        font-family: PingFangSC-Semibold, PingFang SC;
                        font-weight: 600;
                        color: #ffffff;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }

                    > .desc {
                        display: flex;
                        align-items: center;
                        margin: 5px 0 0 18px;
                        font-size: 18px;
                        font-family: PingFangSC-Medium, PingFang SC;
                        font-weight: 500;
                        color: #ffffff;
                        line-height: 24px;
                        > span {
                            padding-left: 3px;
                            color: rgba(255, 94, 0, 1);
                        }
                    }

                    > .dates {
                        padding: 12px 50px 0 50px;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;

                        > .date {
                            width: 48px;
                            height: 48px;
                            background: #ffffff;
                            border-radius: 4px;
                            padding: 0 5px;
                            display: flex;
                            align-items: flex-end;
                            justify-content: center;

                            > .number {
                                font-size: 34px;
                                font-family: PingFangSC-Medium, PingFang SC;
                                font-weight: 500;
                                color: #ff6116;
                                line-height: 47px;
                            }

                            > .symbol {
                                font-size: 10px;
                                font-family: PingFangSC-Medium, PingFang SC;
                                font-weight: 500;
                                color: #000000;
                                line-height: 32px;
                            }
                        }
                    }

                    > .button-block {
                        display: flex;
                        width: 100%;
                        align-items: center;
                        justify-content: center;
                        > .button {
                            margin-top: 21px;
                            width: 126px;
                            height: 34px;
                            background: rgba(255, 97, 22, 1);
                            border-radius: 17px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            font-size: 13px;
                            font-family: PingFangSC-Medium, PingFang SC;
                            font-weight: 500;
                            color: #ffffff;
                            cursor: pointer;
                            user-select: none;
                            &:hover {
                                opacity: 0.9;
                                transition: opacity 0.2s;
                            }
                        }
                    }
                }
            }
        }
    }
}
