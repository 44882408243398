.content {
    width: 100%;
    width: 100%;
    padding: 74px 202px 84px 202px;
    background-image: url("../../../../assets/imgs/main6_bg.png");
    background-size: cover;
    background-repeat: no-repeat;
    > .title {
        display: flex;
        align-items: center;
        padding-bottom: 37px;
        justify-content: center;
        font-size: 36px;
        font-weight: 600;
        color: #fff;
        line-height: 51px;
        letter-spacing: 1px;
        font-family: PingFangSC-Semibold, PingFang SC;
    }

    .list {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;

        &-item {
            width: 226px;
            height: 350px;
            background: #ffffff;
            border-radius: 10px;
            overflow: hidden;
            display: flex;
            flex-direction: column;

            > .img {
                width: 226px;
                height: 133px;
                object-fit: cover;
            }
            &-body {
                flex: 1;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: space-between;
                > .top {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    > .title {
                        font-size: 22px;
                        font-family: PingFangSC-Semibold, PingFang SC;
                        font-weight: 600;
                        color: #2a2a2a;
                        line-height: 31px;
                        padding-top: 18px;
                    }
                    > .text {
                        padding: 9px 15px 0 15px;
                        font-size: 16px;
                        font-family: PingFangSC-Regular, PingFang SC;
                        font-weight: 400;
                        color: #2a2a2a;
                        line-height: 29px;
                    }
                }

                > .button {
                    width: 106px;
                    height: 28px;
                    background: #ff711f;
                    border-radius: 14px;
                    margin: 18px 0;
                    font-size: 12px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #ffffff;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    user-select: none;
                    cursor: pointer;

                    &:hover {
                        opacity: 0.8;
                        transition: opacity 0.2s;
                    }
                }
            }
        }
    }
}
