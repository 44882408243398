.content {
    width: 100%;
    background-color: #fff;
    padding: 85px 0px 77px 0;

    > .title {
        display: flex;
        align-items: center;
        padding-bottom: 44px;
        justify-content: center;
        font-size: 36px;
        font-weight: 600;

        color: #262626;
        line-height: 51px;
        letter-spacing: 1px;
        font-family: PingFangSC-Semibold, PingFang SC;
    }

    .list {
        width: 1000px;
        display: flex;
        margin: auto;
        justify-content: space-around;
        flex-wrap: wrap;
        &-item {
            width: 226px;
            height: 320px;
            // margin-right: 31px;
            background: #ffffff;
            border-radius: 10px;
            border: 1px solid #c1c1c1;
            overflow: hidden;
            margin-bottom: 31px;

            > .img {
                width: 226px;
                height: 133px;
                object-fit: cover;
            }
            &-body {
                display: flex;
                flex-direction: column;
                align-items: center;
                > .title {
                    font-size: 22px;
                    font-family: PingFangSC-Semibold, PingFang SC;
                    font-weight: 600;
                    color: #2a2a2a;
                    line-height: 31px;
                    padding-top: 18px;
                }
                > .text {
                    padding: 9px 15px 0 15px;
                    font-size: 16px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #2a2a2a;
                    text-align: center;
                    line-height: 29px;
                }

                > .button {
                    width: 106px;
                    height: 28px;
                    background: #ff711f;
                    border-radius: 14px;
                    margin-top: 18px;
                    font-size: 12px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #ffffff;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    user-select: none;
                    cursor: pointer;

                    &:hover {
                        opacity: 0.8;
                        transition: opacity 0.2s;
                    }
                }
            }
        }
    }
}
