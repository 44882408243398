.rtr-root {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
    overflow: hidden;
    border-radius: inherit;
}

.rtr-ripple-wrapper {
    position: relative;
    z-index: 3;
    display: block;
    opacity: 1;
    pointer-events: none;
}

.rtr-ripple {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 4;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    opacity: 0;
}

.rtr-ripple-wrapper-exiting {
    opacity: 0;
    animation: rtr-ripple-exit 500ms cubic-bezier(0.4, 0, 0.2, 1);
}

.rtr-ripple-entering {
    transform: scale(1);
    opacity: 0.3;
    animation: rtr-ripple-enter 500ms cubic-bezier(0.4, 0, 0.2, 1);
}

@keyframes rtr-ripple-enter {
    0% {
        transform: scale(0);
    }
    100% {
        transform: scale(1);
    }
}

@keyframes rtr-ripple-exit {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}
