.content {
    width: 100%;
    width: 100%;
    padding: 90px 202px 98px 202px;
    background-color: #fff;
    > .title {
        display: flex;
        align-items: center;
        padding-bottom: 53px;
        justify-content: center;
        font-size: 36px;
        font-weight: 600;
        color: #262626;
        line-height: 51px;
        letter-spacing: 1px;
        font-family: PingFangSC-Semibold, PingFang SC;
    }
    .box {
        display: flex;
        align-items: center;
    }
    .panel {
        margin: auto;
        // width: 100%;
        display: flex;
        align-items: center;

        &-left {
            display: flex;
            flex-direction: column;
            margin-right: 31px;
            .row {
                display: flex;
            }
            .top-card {
                width: 288px;
                height: 279px;
                background: #ffffff;
                border-radius: 10px;
                border: 1px solid #c1c1c1;
                margin-right: 31px;
                padding: 23px 16px;
                &:last-child {
                    margin-right: 0;
                }

                &-header {
                    width: 100%;
                    display: flex;
                    align-items: flex-start;
                    justify-content: space-between;

                    > .text {
                        font-size: 22px;
                        font-family: PingFangSC-Semibold, PingFang SC;
                        font-weight: 600;
                        color: #262626;
                        line-height: 31px;
                        letter-spacing: 1px;
                        padding-right: 20px;
                    }

                    > .icon {
                        width: 69px;
                        height: 69px;
                    }
                }

                &-body {
                    display: flex;
                    flex-direction: column;
                    padding-top: 7px;

                    > .text {
                        font-size: 16px;
                        font-family: PingFangSC-Regular, PingFang SC;
                        font-weight: 400;
                        color: #262626;
                        display: flex;
                        line-height: 34px;
                    }
                }
            }

            .bottom-card {
                height: 359px;
                background: #ffffff;
                border-radius: 10px;
                padding: 23px 16px;
                width: 100%;
                margin-top: 30px;
                border: 1px solid #c1c1c1;
                &-header {
                    width: 100%;
                    display: flex;
                    align-items: flex-start;
                    justify-content: space-between;

                    > .text {
                        font-size: 22px;
                        font-family: PingFangSC-Semibold, PingFang SC;
                        font-weight: 600;
                        color: #262626;
                        line-height: 31px;
                        letter-spacing: 1px;
                        padding-right: 20px;
                    }

                    > .icon {
                        width: 69px;
                        height: 69px;
                    }
                }

                &-body {
                    display: flex;
                    padding-top: 7px;

                    > .text {
                        // width: 270px;
                        font-size: 16px;
                        font-family: PingFangSC-Regular, PingFang SC;
                        font-weight: 400;
                        color: #262626;
                        display: flex;
                        line-height: 42px;
                    }
                }
            }
        }

        &-right {
            display: flex;
            flex-direction: column;
            .top-card {
                width: 359px;
                height: 352px;
                background: #ffffff;
                border-radius: 10px;
                border: 1px solid #c1c1c1;
                padding: 23px 16px;
                margin-bottom: 30px;

                &-header {
                    width: 100%;
                    display: flex;
                    align-items: flex-start;
                    justify-content: space-between;

                    > .text {
                        font-size: 22px;
                        font-family: PingFangSC-Semibold, PingFang SC;
                        font-weight: 600;
                        color: #262626;
                        line-height: 31px;
                        letter-spacing: 1px;
                        padding-right: 20px;
                    }

                    > .icon {
                        width: 69px;
                        height: 69px;
                    }
                }

                &-body {
                    display: flex;
                    flex-direction: column;
                    padding-top: 7px;

                    > .text {
                        font-size: 16px;
                        font-family: PingFangSC-Regular, PingFang SC;
                        font-weight: 400;
                        color: #262626;
                        display: flex;
                        line-height: 28px;
                    }
                }
            }

            .bottom-card {
                width: 359px;
                background: #ffffff;
                border-radius: 10px;
                height: 294px;
                border: 1px solid #c1c1c1;
                padding: 23px 16px;

                &-header {
                    width: 100%;
                    display: flex;
                    align-items: flex-start;
                    justify-content: space-between;

                    > .text {
                        font-size: 22px;
                        font-family: PingFangSC-Semibold, PingFang SC;
                        font-weight: 600;
                        color: #262626;
                        line-height: 31px;
                        letter-spacing: 1px;
                        padding-right: 20px;
                    }

                    > .icon {
                        width: 69px;
                        height: 69px;
                    }
                }

                &-body {
                    display: flex;
                    flex-direction: column;
                    padding-top: 7px;

                    > .text {
                        font-size: 16px;
                        font-family: PingFangSC-Regular, PingFang SC;
                        font-weight: 400;
                        color: #262626;
                        display: flex;
                        line-height: 32px;
                    }
                }
            }
        }
    }
}
